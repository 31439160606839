<template>
    <div>
        <div :class="isZh?'home-content globalHomeContent':'home-content'">
            <div class="banner">
                <p class="title">{{$t('WDNPC_WEB_ABOUT_US',{text:'关于我们'})}}</p>
                <p class="content">
                    {{$t('WDNPC_WEB_WEDON',{text:'伟东云教育'})}}{{$t('WDNPC_WEB_WEDON_ORGAN',{text:'是一个公正的组织，旨在通过整合新技术和现有技术，支持教育机构或公共部门改革教学。我们坚持的理念是，教育应该通过实用和易用的技术让公众接受。'})}}
                </p>
            </div>
            <div class="dn-star">
                <p class="title">
                    {{$t('WDNPC_WEB_WEDON_START',{text:'你是如何开始的'})}}
                </p>
                <div class="srot">
                    <div class="sort-item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/sorbg1.png" alt="" class="bg-pl1 bg-pl">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/sra.png" alt="">
                            <p>{{$t('WDNPC_WEB_WEDON_ACCOUNT',{text:'注册您的帐户'})}}</p>
                    </div>
                    <div class="sort-item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/sorbg2.png" alt="" class="bg-pl2 bg-pl">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/srb.png" alt="">
                        <p>{{$t('WDNPC_WEB_WEDON_SELECT_COURSE',{text:'选择课程开始学习'})}}</p>
                    </div>
                    <div class="sort-item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/sorbg3.png" alt="" class="bg-pl3 bg-pl">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/srcc.png" alt="">
                        <p>{{$t('WDNPC_WEB_WEDON_EXAM',{text:'参加考试'})}}</p>
                    </div>
                    <div class="sort-item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/sorb4.png" alt="" class="bg-pl4 bg-pl">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/srd.png" alt="">
                        <p>{{$t('WDNPC_WEB_WEDON_RESULT',{text:'获得成果'})}}</p>
                    </div>
                </div>
            </div>
            <div class="carcen">
                <div class="carcen-title">
                    <div class="title-box">
                        <p class="title">{{$t('WDNPC_WEB_WEDON_TRAIN_ZHIYE_COURSE',{text:'职业课程'})}}</p>
                        <p class="title2">{{$t('WDNPC_WEB_WEDON_EXPLORE',{text:'多种多样的职业供您去探索'})}}</p>
                    </div>
                </div>
                <div class="carcen1 carcen-item">
                    <div class="carcen-item-left carcen-item-img">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/car1.png" alt="">
                    </div>
                    <div class="carcen-item-right  carcen-item-text">
                        <p class="title">
                            {{$t('WDNPC_WEB_WEDON_STUDY',{text:'学习管理系统'})}}
                        </p>
                        <p class="content">
                            {{$t('WDNPC_WEB_WEDON_SOLUTION',{text:'在我们的总体解决方案中，LMS是利用基础设施能力和容纳内容/人才/教学法以通过数字化转型实现的中心平台。'})}}
                        </p>
                        <div class="btn">
                            <button>{{$t('WDNPC_WEB_WEDON_STUDY_MORE',{text:'了解更多'})}}</button>
                        </div>
                    </div>
                </div>
                <div class="carcen2 carcen-item">
                    <div class="carcen-item-left carcen-item-text">
                        <p class="title">
                            {{$t('WDNPC_WEB_WEDON_SHEBEI',{text:'智能教室和智能设备'})}}
                        </p>
                        <p class="content">
                            {{$t('WDNPC_WEB_WEDON_BIANGE',{text:'课堂上正在发生变革。伟东智能教室为传统教室配备成套设备和VR、微课程制作工具等创新智能设备，使课堂活动具有数字化互动性和生产性'})}}
                        </p>
                        <p class="content">
                            {{$t('WDNPC_WEB_WEDON_SHEBEI_GAO',{text:'我们还以自己的品牌提供经济高效的设备。'})}}
                        </p>
                        <div class="btn">
                            <button>{{$t('WDNPC_WEB_WEDON_STUDY_MORE',{text:'了解更多'})}}</button>
                        </div>
                    </div>
                    <div class="carcen-item-right carcen-item-img">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/car2.png" alt="">
                    </div>
                </div>
                <div class="more-carcen">
                    <p class="title">{{$t('WDNPC_WEB_WEDON_FOR_MORE',{text:'我们为您提供更多选择'})}}</p>
                    <ul>
                        <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/more1.png" alt="">
                            <div class="bottom">
                                <p class="title">{{$t('WDNPC_WEB_WEDON_SOFTWARE',{text:'基础设施'})}}</p>
                                <p class="content">{{$t('WDNPC_WEB_WEDON_SOFTWARE_CONTENT',{text:'我们在提供教育目的建设和ICT基础设施方面拥有丰富的经验，如学校综合楼、数据中心和校园网。这使得所有上层应用程序和教学法都能够在坚实的基础上应用，而不是在空中应用。'})}}
</p>
                            </div>
                        </li>
                         <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/more2.png" alt="">
                            <div class="bottom">
                                <p class="title">{{$t('WDNPC_WEB_WEDON_STRUCTURE',{text:'内容提供和知识转移'})}}</p>
                                <p class="content">{{$t('WDNPC_WEB_WEDON_STRUCTURE_CONTENT',{text:'伟东和我们的附属品牌以多种语言提供现成的职业培训内容。我们还提供培训师培训计划，以提供如何掌握教育数字投资以及最大化价值和投资回报率的知识。'})}}
</p>
                            </div>
                        </li>
                         <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/more3.png" alt="">
                            <div class="bottom">
                                <p class="title">{{$t('WDNPC_WEB_WEDON_ARCHITE',{text:'为学生建坚强的壁垒'})}}</p>
                                <p class="content">{{$t('WDNPC_WEB_WEDON_ARCHITE_CONTENT',{text:'伟东云教育集团是伟东集团的成员，专业从事商业地产开发。集团已成功实施各类物业，包括高端住宅、学校、购物中心、工业园区、文化园区和五星级酒店。伟东云教育拥有丰富的经验和与中国建筑设计机构/建筑公司的联系，能够提供精美的建筑设计和高品质的综合建筑。'})}}
</p>
                            </div>
                        </li>
                    </ul>
                    <div class="btn">
                        <button>
                            {{$t('WDNPC_WEB_WEDON_SEE_MORE',{text:'查看更多'})}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="trial">
                <div class="trial-title">
                    <p class="title">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON',{text:'试听课'})}}</p>
                    <p class="title2">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_TRY',{text:'现在可以试听的课程'})}}</p>
                </div>
                <ul>
                    <li>
                        <div class="top" @click="viewVideo('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/video/FormatFactoryPart1.mp4')">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/trial.png" alt="">
                        </div>
                        <p class="title">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_ONE',{text:'人工智能应用管理师（初级）'})}}</p>
                        <p class="content">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_COTENT_ONE',{text:'是由工业和信息化部教育与考试中心（CEIAEC）面向人工智能行业从业者推出的职业能力等级评定项目。'})}}</p>
                    </li>
                    <li>
                        <div class="top" @click="viewVideo('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/video/FormatFactoryPart2.mp4')">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/trial2.png" alt="">
                        </div>
                        <p class="title">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_TWO',{text:'人工智能应用管理师职业能力等级评价项目'})}}</p>
                        <p class="content">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_COTENT_TWO',{text:'是由工业和信息化部教育与考试中心（CEIAEC）面向人工智能行业从业者推出的职业能力等级评定项目。该项目面向当前人工智能应用管理人才极度紧缺的现状，弥补了权威性人工智能人才能力培养体系和评价体系标准的空白，作为国内人工智能领域首个职业能力等级评价标准。为人工智能人才培养，人才评估和企业招聘提供了重要的依据。目前，《人工智能应用管理师职业能力等级评价证书》已经得到越来越多用人单位的认可，助力求职者实现更高职业梦想。'})}}</p>
                    </li>
                    <li>
                        <div class="top" @click="viewVideo('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/video/FormatFactoryPart3.mp4')"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/trial3.png" alt=""></div>
                        <p class="title">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_THREE',{text:'.基于LDA+SVM实现人脸识别'})}}</p>
                        <p class="content">{{$t('WDNPC_WEB_WEDON_TRIAL_LESSON_COTENT_THREE',{text:'包括LDA、PCA降维算法使用'})}}</p>
                    </li>
                </ul>
                <div class="btn">
                    <button>
                        {{$t('WDNPC_WEB_WEDON_SEE_MORE',{text:'查看更多'})}}
                    </button>
                </div>
            </div>
            <div class="coo">
                <div class="coo-text">
                    <p class="title">{{$t('WDNPC_WEB_WEDON_COOPER_PARTNER',{text:'合作伙伴'})}}</p>
                    <p class="content">{{$t('WDNPC_WEB_WEDON_FORWARD',{text:'我们期待与您合作'})}}</p>
                </div>
                <ul>
                    <li v-for="(item,index) in cooList" :key="index">
                        <img :src="item.src" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <footer class="footer">
            <div class="footer-content">
                <div class="footer-up">
                    <div class="col1">
                        <ul>
                            <li class="title">
                                <a href="https://global.wedon.com/project" target="_blank">{{$t('WDNPC_WEB_WEDON_PRODUCTS',{text:'解决方案和产品'})}}</a>
                            </li>
                            <li class="">
                                <a href="https://global.wedon.com/project" target="_blank">{{$t('WDNPC_WEB_WEDON_OVERVIEW',{text:'概况'})}}</a>
                            </li>
                            <li class="">
                                <a href="https://global.wedon.com/data" target="_blank">{{$t('WDNPC_WEB_WEDON_DATA_CENTER',{text:'数据中心'})}}</a>
                            </li>
                            <li class=""> 
                                <a href="https://global.wedon.com/welmshome" target="_blank">{{$t('WDNPC_WEB_WEDON_WELMS',{text:'WeLMS'})}}</a>    
                            </li>
                            <a href="//global.wdyedu.com/" target="_blank"> {{$t('WDNPC_WEB_WEDON_DEMOS',{text:' Demos云课'})}} </a>
                            <li class=""> 
                                <a href="https://global.wedon.com/classrooms" target="_blank">{{$t('WDNPC_WEB_WEDON_CLASSROS',{text:'智慧教室'})}} </a>
                            </li>
                            <li class="">
                                <a href="https://global.wedon.com/look" target="_blank">{{$t('WDNPC_WEB_WEDON_LOOK',{text:'鹿课'})}} </a>
                            </li>
                            <li class="">
                                <a href="https://global.wedon.com/solution" target="_blank">{{$t('WDNPC_WEB_WEDON_SLOUTIONS_W',{text:'虚拟现实'})}} </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col2">
                        <ul>
                            <li class="title"> 
                                <a href="https://global.wedon.com/news">{{$t('WDNPC_WEB_WEDON_NEWS',{text:'新闻'})}}</a>
                            </li>
                            <li class="title"> 
                                <a href="https://global.wedon.com/aboutus">{{$t('WDNPC_WEB_ABOUT_US',{text:'关于我们'})}}</a>
                            </li>
                            <li class=""> 
                                <a href="https://global.wedon.com/aboutus?tabNum=0" target="_blank">{{$t('WDNPC_WEB_WEDON_PROFILE',{text:'简介'})}}</a>
                             </li>
                            <li class=""> 
                                <a href="https://global.wedon.com/aboutus?tabNum=1" target="_blank">{{$t('WDNPC_WEB_WEDON_PARTNERSHIPS',{text:'合作伙伴'})}}</a>
                            </li>
                            <li class="">
                                 <a href="https://global.wedon.com/aboutus?tabNum=2" target="_blank">{{$t('WDNPC_WEB_WEDON_CONCACT',{text:'联系方式'})}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col3">
                        <div class="info"> {{$t('WDNPC_WEB_WEDON_PRODUCTS_AND_COMPANIES',{text:'伟东云教育集团体现了专注于不同教育领域的公司和产品。请随时浏览我们的品牌网站。'})}} </div>
                        <div class="item" v-for="(line,index) in flogoList" :key="index">
                            <div class="item-col" v-for="(item,ind) in line.line" :key="ind">
                                <a href="https://www.demos.fr/" target="_blank">
                                    <img :src="item.src" alt="" class="default">
                                    <img :src="item.src2" alt="" class="mhover">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-center">
                    <ul>
                        <li class="" style="cursor: pointer;"></li>
                        <li v-for="(item,index) in fooList" :key="index">
                            <a :href ="item.index" target="_blank">
                                <img :src="item.src2" />
                                <img :src="item.src" class="mover"/>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="footer-down">
                    <div class="line-1"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/flogo.png" alt="">
                        <span style="margin-left: 0px;">|</span>
                        <span>© {{$t('WDNPC_WEB_WEDON_YINSI_ITEMS',{text:'隐私及条款'})}}&nbsp;&nbsp;
                            <a href="/privacy" class="">{{$t('WDNPC_WEB_WEDON_BANQUAN',{text:'版权所有'})}}</a>
                        </span>
                    </div>
                    <div>
                        <a target="_blank" href="https://beian.miit.gov.cn/">京ICP备10026535号</a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    name:'GlobalHome',
    data(){
        return{
            hefOrgin:window.location.origin,
            isZh:true,
            cooList:[
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo1.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo2.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo3.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo4.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo5.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo6.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo7.png'},
                {src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coo8.png'}
            ],
            fooList:[
                {
                    href:'https://www.facebook.com/Weidong-Cloud-Education-Group-105335877922654',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ft1.png',
                    src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ftt1.png'
                },
                {
                    href:'https://www.linkedin.com/company/weidong-cloud-education-group',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ft2.png',
                    src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ftt2.png'
                },
                {
                    href:'https://twitter.com/WeidongGroup',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ft3.png',
                    src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ftt3.png'
                },
                {
                    href:'https://www.youtube.com/channel/UCVCAEYCuDseYaOXG-YPS2PQ/videos',
                    src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ft4.png',
                    src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/ftt4.png'
                }
            ],
            flogoList:[
                {
                    line:[
                        {
                            href:'https://www.demos.fr/',
                            src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer1.png',
                            src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer11.png'
                        },
                        {
                            href:'https://www.mindonsite.com/en/',
                            src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer2.png',
                            src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer22.png'
                        },
                        {
                            href:'https://international.brest-bs.com/',
                            src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer3.png',
                            src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer33.png'
                        },
                    ]
                },{
                    line:[
                        {
                            href:'https://www.hemsleyfraser.com/',
                            src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer4.png',
                            src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer44.png'
                        },
                        {
                            href:'//www.simultrain.swiss/',
                            src:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer5.png',
                            src2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/footer55.png'
                        }
                    ]
                }
            ]
        }
    },
   watch:{
        '$store.state.app.language':{
            handler(){
                const lang = this.$store.state.app.language;
                console.log('fffffffffffffffffffff');
                console.log(lang);
                if(lang=='zh-CN' || lang=='zh-cn' || lang =='zh'){
                    this.isZh= true
                }else{
                    this.isZh = false;
                }
                console.log(this.isZh);
            },
            deep:true,
        },
   },
    methods:{
        viewVideo(params){
            this.$router.push({
                path:'/newcms/video',
                query:{
                    entryUrl:params
                }
            })
        }
    }
}
</script>
<style lang="stylus" scoped>
.globalHomeContent{
    .dn-star .srot .sort-item p,.carcen-title .title2{
        font-size:16px!important;
        line-height:26px !important;
    }
    .carcen-item .carcen-item-text .content,.more-carcen ul li .content{
        font-size:16px !important;
        line-height:26px !important;
    }
    .trial .trial-title .title2, .trial ul li .content, .trial ul li .content{
        font-size:16px!important;
        line-height:26px !important;
    }
}
button{
    &:hover{
        cursor pointer    
    }
}
img{
    vertical-align middle;
}
.home-content{
    word-break: break-word;
    .banner{
        padding-top:300px;
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/home-banner.png') no-repeat center center;
        background-size cover;
        text-align:center;
        padding-bottom 280px;
        .title{
            font-size:64px;
            margin-bottom 47px;
            color:#fff;
        }
        .content{
            max-width 850px;
            font-size 19px;    
            line-height 32px;
            margin: 0 auto;
            color:rgba(255,255,255,.85)
        } 
    }
    .dn-star{
        background:rgba(39, 45, 59, 1);
        padding-top:100px;    
        .title{
            font-size:30px;
            line-height:38px;
            text-align:center;
            color:#fff;    
            padding-bottom:139px;
        }
        .srot{
            color:rgba(255,255,255,.85)
            display:flex;
            justify-content center;
            padding-bottom:100px;
            .sort-item{
                width:140px;
                text-align:center;
                position:relative;
                margin-right:182px;
                img{
                    width:64px;
                } 
                p{
                    margin-top:23px;
                    color:rgba(255,255,255,.85)
                }
                &:nth-last-child(1){
                    margin-right:0;
                    &::after{
                        width:0;
                    }
                }
                &::after{
                    position:absolute;
                    content:'';
                    left:112px;
                    right:0;
                    height:0px;
                    top:32px;
                    width:178px;
                    border-bottom:3px dashed rgba(120, 168, 219, 0.3);
                }
                .bg-pl{
                    width:auto;
                    height:121px;
                    position:absolute;
                    top:-83px;
                }
                .bg-pl1{
                    left:-10px;
                }
                .bg-pl2{
                    left:-28px;
                }
                .bg-pl3{
                    height:81px;
                    left:-10px;
                }
                .bg-pl4{
                    height:125px;
                    left:-20px;
                }
            }   
        }
    }
    .carcen-title{
        width:1200px;
        margin: 0 auto;
        position:relative;
        height: 205px;
        color:#fff;
        .title-box{
            position:absolute;
            z-index:3;
            left:50%;
            transform:translateX(-50%);
            top:100px; 
            text-align center;
        }
        &:after{
           content: '';
            width: 418px;
            height: 158px;
            background-color: #2d53d3;
            display: inline-block;
            position: absolute;
            left: 50%;
            margin-left:-209px;
            top: 85px;
            z-index:2;
            transform: perspective(84px) rotateX(136deg);
        }
        .title{
            font-size:30px;
            line-height 38px;
        }
        .title2{
            font-size:14px;
            line-height:22px;
            margin-top:10px;
            color:rgba(255,255,255,.85)    
        }
    }
    .carcen1{
        .carcen-item-text{
            padding-right:85px;
        }
        .carcen-item-img{
            padding-left:85px;
        }
    } 
    .carcen2{
        margin-bottom:0!important;
        .carcen-item-img{
            text-align right;
            padding-right:85px;
        }
        .carcen-item-text{
            padding-left:85px;
        }
    }
    .carcen-item{
        width: 1200px;
        height: 300px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 4px solid #3D3D3D;
        display:flex;
        margin:175px auto 250px;
        .carcen-item-img{
            flex:1;
            img{
                width:380px;
                margin-top:-75px;
            }
        }
        .carcen-item-text{
            flex:1;
            display :flex;
            flex-direction column;
            justify-content center;
            .title{
                color:#3D3D3D;
                font-size:20px;
                line-height:28px;
            }
            .content{
                color:rgba(61, 61, 61, 0.85);
                margin-top:8px;
                margin-bottom:8px;
                word-break:break-word;
            }
            .message{
                color:rgba(61, 61, 61, 0.45);
                margin-bottom:8px;
            }
            .btn{
                text-align:right;    
            }
            button{
                background:rgba(45, 83, 211, 1);
                border:none;
                outline:none;
                color:#fff;
                width:121px;
                height:38px;
                line-height 38px;
                cursor pointer;
                box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.2);
            }
        }
    }
    .more-carcen{
        .title{
            padding-top:225px;
            padding-bottom:100px;
            text-align:center;
            font-size:30px;
            line-height :38px;
            word-break: break-word;
        }
        ul{
            width:1200px;
            margin: 0 auto;
            display flex;
            justify-content space-around;
            li{
                width: 360px;
                background: #FFFFFF;
                box-shadow: 0px 7px 18px 1px rgba(0,0,0,0.10000000149011612);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                margin-bottom:100px;
                text-align:center;
                padding-bottom:21px;
                img{
                    width:100%;
                }
                .title{
                    padding-top:24px;
                    padding-bottom:10px;
                    font-size:20px;
                    line-height :28px;
                }
                .content{
                    display: -webkit-box;
                    overflow: hidden;
                    white-space: normal !important;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical
                    font-size:14px;
                    line-height :22px;
                    color:rgba(61, 61, 61, 0.85);
                    padding:0 30px 0;
                }
                .message{
                    padding-bottom:21px;
                    font-size:12px;
                    line-height 28px;
                    color:rgba(61, 61, 61, 0.49);
                }
            }
        }
        .btn{
            text-align:center;
            padding-bottom:100px;
            button{
                width: 134px;
                height: 48px;
                background: #2D53D3;
                box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                color:#fff;
                font-size:16px;
                line-height:48px;
                border:none;
                outline:none;
            }
        }

    }
    .trial{
        a{
            display:block;
        }
        .trial-title{
            text-align:center;
            color:#fff;
            padding-bottom:100px;
            .title{
                font-size:30px;
                line-height:38px;
                margin-bottom:8px;

            }
            .title2{
                font-size:14px;
                line-height:22px;
                color:rgba(255,255,255,.85)
            }
        }
        background:rgba(39, 45, 59, 1);
        padding-top:100px;
        padding-bottom:100px;
        .top{
            position:relative;
            width:375px;
            height:250px;
            img{
                width:100%;
            }
            &::after{
                position absolute;
                top:50%;
                content:'';
                left:50%;
                width:64px;
                height:64px;
                background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/play.png') no-repeat center center;
                background-size:cover;
                transform:translateX(-50%) translateY(-50%);
            }  
        }    
        ul{
            display flex;
            width:1200px;
            margin:0 auto;
            justify-content:space-around;
            li{
                width:375px;
                text-align:center;
                img{
                    width:100%;
                }
                .title{
                    padding-bottom:3px;
                    padding-top:8px;
                    font-size:19px;
                    line-height 28px;
                    color:#fff;
                }
                .content{
                    display: -webkit-box;
                    overflow: hidden;
                    white-space: normal !important;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical
                    font-size:14px;
                    padding-left:50px;
                    padding-right:50px;
                    line-height:22px;
                    color:rgba(255,255,255,.6);    
                }
            }
        }
        .btn{
            text-align:center;
            padding-top:100px;
            button{
                width: 134px;
                height: 48px;
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.20000000298023224);
                border-radius: 0px 0px 0px 0px;
                opacity: 1;
                color:rgba(45, 83, 211, 1);
                line-height 48px;
                font-size:16px;
                border:none;
                outline:none;
            }
        }
    }
    .coo{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/global/coobg.png') no-repeat center center;
        background-size cover;
        padding-top:120px;
        .coo-text{
            width:1200px;
            margin:0 auto;
            color:#fff;
            .title{
                font-size 30px;
                line-height 38px;
                margin-bottom 8px;
            } 
            .content{
                font-size:14px;
                line-height 22px;
            }       
        }
        ul{
           display :flex;
           padding-top:100px;
           width:1200px;
           margin: 0 auto;
           flex-wrap:wrap;
            li{
                margin-bottom:72px;
                margin-right 80px;
                img{
                    vertical-align:middle;
                    width:240px;
                }
                &:nth-child(4n){
                    margin-right:0px;    
                }
            }
        }
    }
}
.footer{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #131313;
    padding-top: 50px;
    color: #fff;
    .footer-content {
        width: 1200px;
        margin: 0 auto;
        .footer-up {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            .col1 {
                font-size: 14px;
                font-family: HelveticaNeue, Arial, sans-serif;
                font-weight: 400;
                color: #666;
                width: 220px;

                li~li {
                    cursor: pointer;
                    margin-top: 8px;
                }
                li{
                    &:hover{
                        color:#fff;
                    }
                }

                a {
                    display: inline-block;
                    cursor: pointer;
                    margin-top: 8px;
                    text-decoration: none;
                    color: #666;
                    &:hover{
                        color:#fff;
                    }
                }

                .title {
                    margin-bottom: 13px;
                    a{
                            color: #fff;
                            font-size: 18px;
                            font-family: HelveticaNeue, Arial, sans-serif;
                            font-weight: 700;
                            cursor: pointer;
                    }
                }
                .title~.title {
                    margin-bottom: 12px;
                }
            }
            .col2{
                li{
                    margin-top:8px;
                    &:hover{
                        color:#fff;    
                    }
                    a{
                        color:#666;
                    }
                }
                .title {
                    margin-bottom: 21px;
                    a{
                        color: #fff;
                        font-size: 18px;
                        font-family: HelveticaNeue,Arial,sans-serif;
                        font-weight: 700;
                        cursor: pointer;
                    }
                }    
            }
            .col3{
                margin-left:80px;    
                .info{
                    font-size: 14px;
                    line-height: 1.5;
                    font-family: HelveticaNeue,Arial,sans-serif;
                    width: 500px;
                    margin-bottom: 33px;
                }
                .item{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    margin-bottom: 7px;
                    width: 376px;
                    height: 50px;
                    .item-col{
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        font-size: 0;
                        &:hover{
                            cursor: pointer
                            img{
                                background:rgba(255,255,255,.3);
                            }
                        }
                        img{
                            height:50px;
                        }
                    }
                }
            }
        }
        .footer-center {
            margin-top: 30px;
            height: 44px;

            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                color:#666;
                .mover {
                    display: none;
                }

                img {
                    width: 44px;
                }

                li {
                    margin-right: auto;
                }

                li~li {
                    cursor: pointer;
                    width: 44px;
                    margin-right: 26px;
                    &:hover{
                        .mover{
                            display:block;
                        }
                        img{
                            display:none;    
                        }
                    }
                }
            }
        }
        .footer-down {
            margin-top: 48px;
            padding-bottom:24px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            border-top: 1px solid hsla(0, 0%, 100%, 0.3);
            padding-top: 24px;
            a {
                color: #fff;
            }a {
                color: #fff;
            }
            .line-1 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;

                img {
                    margin-right: 5px;
                    position: relative;
                    top: -4px;
                    height: 20px;
                }

                span {
                    font-family: HelveticaNeue, Arial, sans-serif;
                    font-weight: 400;
                    margin-left: 5px;
                }
            }
        }
    }
}
</style>
<style scoped>
/* @media screen and (max-width: 1600px) {
    .home-content .banner{
        padding-top:227px;
        padding-bottom:210px;
    }
    .home-content .banner .title{
        font-size:48px;
        line-height:54px;
        margin-bottom:35px;
    }
    .home-content .banner .content{
        font-size:15px;
        line-height:24px;
    }
    .home-content .coo{
        padding-top:90px;
        padding-bottom: 105px;
    }
    .home-content .coo ul{
        padding-bottom: 75px;
    }
    .home-content .coo ul li {
        margin-right: 60px;
        margin-bottom: 54px;
    }
    .coo .coo-text .title{
        font-size:23px;
        line-height: 29px;
    }
    .coo .coo-text .content{
        font-size: 12px;
        line-height: 18px;
    }
} */
</style>

